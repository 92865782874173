<template>

  <v-list-item @click="$emit('selectApp', app)">
		<v-list-item-avatar>
			<v-img v-if="app.icon" :src="app.icon.url" />
		</v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ app.name }}
		
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>

</template>

<script>
export default {
	name: 'AppListItem',
	props: {
		app: {
			default: () => {},
			required: true,
			type: Object
		}
	}
}
</script>
